import React from "react"
import PropTypes from "prop-types"
import Glide from "@glidejs/glide"
import "../../../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css"
import "../../blocks/types/gallerySlider.css"

const GallerySlide = ({ image, imageMobile }) => {
    return (
        <li className="glide__slide">
            <img src={image.url || ''} alt={image.asset_alt || ''}  className="desktop w-full" />
            <img src={imageMobile.url || image.url || ''} alt={imageMobile.asset_alt || ''} className="mobile w-full" />
        </li>
    );
}

GallerySlide.propTypes = {
    image: PropTypes.object.isRequired,
    imageMobile: PropTypes.object.isRequired,
}


class Gallery extends React.Component {
    componentDidMount() {
        const { option_slider_autoplay, perView, id } = this.props

        const autoplay = option_slider_autoplay * 1000;
    
        this.glide = new Glide(`.glide_${id}`, {
            autoplay,
            perView,
        })
    
        this.glide.mount()
    }

    componentWillUnmount() {
        this.glide.destroy()
    }


    render() {

        const { block_gallery, id } = this.props
        return (
            <section className="my-16">
                <div className="block__gallerySlider gallerySlider">
                    <div id={id} className={` glide glide_${id}`}>
                    <div className="glide__track" data-glide-el="track">
                        <ul className="glide__slides">
                        {block_gallery.map((gallerySlide, index) => (
                            <GallerySlide 
                                image={gallerySlide.gallery_image[0] || {}} 
                                imageMobile={gallerySlide.gallery_image_mobile[0] ||gallerySlide.gallery_image[0] || {}}
                                key={index} 
                            />
                        ))}
                        </ul>
                    </div>
                    <div data-glide-el="controls">
                        <button className="controls__button controls__button-left glide__arrow--left" data-glide-dir="<" />
                        <button className="controls__button controls__button-right glide__arrow--right" data-glide-dir=">" />
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

Gallery.propTypes = {
    id: PropTypes.string,
    option_slider_autoplay: PropTypes.number,
    block_gallery: PropTypes.array.isRequired,
    perView: PropTypes.number,
  }
  
  Gallery.defaultProps = {
    option_slider_autoplay: 3000,
    block_gallery: [],
    perView: 1,
}

export default Gallery


import { graphql } from 'gatsby'
export const query = graphql`
	fragment NeoPostGalleryFragment on Craft_post_content_gallery_BlockType {
        typeHandle
        id
        option_slider_autoplay
        block_gallery {
            ... on Craft_block_gallery_image_BlockType {
                gallery_image {
                    id
                    url
                    ... on Craft_assets_Asset {
                        asset_caption
                        asset_alt
                    }
                }
                gallery_image_mobile {
                    id
                    url
                    ... on Craft_assets_Asset {
                        asset_caption
                        asset_alt
                    }
                }
            }
        }
    }
`