import React from "react"
import PropTypes from "prop-types"
import PostContent from "./postContent"

class NeoPostContent extends React.Component {
    constructor(props){
		super(props)
		this.getData = this.getData.bind(this);
	}

	getData(key) {
		return this.props.globProps.find(x => x.key == key) || {};
	}

	render(){
		const { blocks } = this.props;
		const data = {
			getData: this.getData,
		}

		return (
			<PostContent data={data} blocks={blocks || []} />
		);

	}
}

NeoPostContent.propTypes = {
	blocks: PropTypes.array,
	globProps: PropTypes.array,
}

export default NeoPostContent;


