import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"
import Parser from "html-react-parser";
import Layout from "../components/layout"
import NeoPostContent from "../components/neo/content"
import PageHero from "../components/images/pageHero"
import { getPrettyDate, getStandardDate } from "../utils/dates"
import { isLoggedIn, isBrowser, logoutByLocation, getAccentColorByLocation } from "../services/auth"

import { graphql } from "gatsby"
import RenderMarkdown from "../core/renderMarkdown"

export const query = graphql`
	query ($id: [Craft_QueryArgument], $language: [String]) {
		craft {
			entry(id: $id, site: $language) {
				... on Craft_communications_store_communications_store_Entry {
					id
					title
					post_accentColor
					post_featureImage {
					  id
					  url
					  ... on Craft_assets_Asset {
							desktop: url(handle: "hero")
							mobile: url(handle: "medium")
							asset_alt
						}
					}
					block_markdown
					block_files {
            id
						url
            title
          }
          post_scripts_styles {
						id
						kind
						url
						mimeType
        		extension
					}
				}
        ... on Craft_communications_office_communications_office_Entry {
					id
					title
					post_accentColor
					post_featureImage {
					  id
					  url
					  ... on Craft_assets_Asset {
							desktop: url(handle: "hero")
							mobile: url(handle: "medium")
							asset_alt
						}
					}
					block_markdown
					block_files {
            id
						url
            title
          }
          post_scripts_styles {
						id
						kind
						url
						mimeType
        		extension
					}
				}
			}
		}
	}
`;



const Communication = ({ data, pageContext }) => {
  const { t } = useTranslation(`default`);

  const { entry } = data.craft
  const { 
		post_accentColor, 
    post_featureImage,
    block_markdown, 
    block_files, 
    post_scripts_styles,
  } = data.craft.entry
  
  const { 
    globalData, 
    posts,
    type,
    loginPath,
    prevUrl,
    nextUrl,
    currentPage,
    totalPages,
  } = pageContext;

  if(!isLoggedIn(type)){
    if(isBrowser()){
      navigate(loginPath);
    }
    return (<></>);
  }

  const logoutAction = (type) => {
    logoutByLocation(type);
  }

  const accentColor = post_accentColor ? post_accentColor.replace('_accent', '') : (getAccentColorByLocation(type) || 'pink');

  return (
    <>
      <Layout { ...globalData } ressourceAssets={post_scripts_styles}>
        {(isLoggedIn(type) || !isBrowser()) &&
            <>
              {(post_featureImage && post_featureImage[0]) && 
                <PageHero 
                  image={post_featureImage[0]}
                  imageMobile={post_featureImage[1] ? post_featureImage[1] : post_featureImage[0] }
                />
              }

              <div className={`page-container max-w-5xl mx-auto title-color-container relative block p-0 ${accentColor}_accent`} >
                <section className={`intro-block max-w-5xl m-auto px-8 mt-8`}>
                  <h1 className={`intro-block-title title text-center text-4xl lg:text-5xl uppercase`}>{t(`communication`)}</h1>
                  <h2 className={`text-3xl lg:text-4xl text-bold text-center uppercase`} >{entry.title}</h2>
                  <RenderMarkdown md={block_markdown} />
                  <div className="flex flex-col lg:flex-row justify-center">      
                    {block_files && block_files.map(({id, url, title}, x) => (
                      <a id={id || ''} key={x} href={url} className={`ardene-btn brand-cta font-bold text-base flex flex-col items-center m-20 link_underline ${accentColor}_underline`} >
                        {title}
                      </a>
                      )
                    )}
                  </div>
                </section>

                <section className="relative py-12 mx-auto px-8 bg-white">
                    <div className={`relative block w-full max-w-5xl bg-${accentColor}-xlight py-1 m-auto`} >
                        {posts && posts.map((postEntry, i) => (
                            <article className="relative block bg-white p-6 lg:p-12 w-auto mt-3 m-4" key={i}>
                                <time
                                    className="text-sm block opacity-50 mb-2"
                                    dateTime={getStandardDate(postEntry.postDate)}
                                >
                                    {getPrettyDate(postEntry.postDate)}
                                </time>
                                <h3 className="text-3xl font-display mb-2 text-bold">
                                    {postEntry.title}
                                </h3>

                                {postEntry.post_summary &&  Parser(postEntry.post_summary) }

                                <NeoPostContent 
                                    accentColor={accentColor}
                                    blocks={postEntry.post_content} 
                                    globProps={globalData.globProps}
                                />
                            </article>
                        ))}
                    </div>
                </section>
                <div className="pb-8 lg:pb-24">
                    <nav className="" role="navigation" aria-label="Pagination Navigation">
                        <ul className="flex justify-center">
                        {prevUrl && (
                            <li className="mx-2">
                                <a href={prevUrl} className="text-blue-600">
                                    &larr; {t(`previous`)}
                                </a>
                            </li>
                        )}
                        <li className="mx-2">
                            {currentPage} {t(`of`)} {totalPages}
                        </li>
                        {nextUrl && (
                            <li className="mx-2">
                                <a href={nextUrl} className="text-blue-600">
                                    {t(`next`)}
                                </a>
                            </li>
                        )}
                        </ul>
                    </nav>
                </div>
                <div className="my-8 text-right max-w-5xl mx-auto" >
                  <a href={loginPath} className=" opacity-50" onClick={() => logoutAction(type)}>{t(`exit`)}</a>
                </div>
            </div>
          </>
        } 
      </Layout>
    </>
  );
}

Communication.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Communication