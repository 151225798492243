import React from "react"
import PropTypes from "prop-types"
import Parser from "html-react-parser"

const Pdf = ({ block_link__label, block_pdf }) => {
    return (
        <>
            {(block_pdf && block_pdf[0]) && 
                <section className="my-8 flex flex-col items-center">
                    <div className="flex flex-col lg:flex-row ">
                        <a href={block_pdf[0].url || ''} target={"_blank"} rel="noreferrer" className={`ardene-btn brand-cta font-bold text-base flex-auto items-center my-4 mx-auto self-center link_underline w-auto text-center accent_underline`}>
                            {Parser(block_link__label)}
                        </a>
                    </div>
                </section>
            }
        </>
    );
}

Pdf.propTypes = {
    block_link__label: PropTypes.string.isRequired,
    block_pdf: PropTypes.array.isRequired,
}

export default Pdf

import { graphql } from 'gatsby'
export const query = graphql`
	fragment NeoPostPDFFragment on Craft_post_content_pdf_BlockType {
        typeHandle
        block_link__label
        block_pdf {
            url
        }
    }
`