import React from "react"
import PropTypes from "prop-types"
import Parser from "html-react-parser"
import RenderMarkdown from "../../../../core/renderMarkdown"

class MarkdownContent extends React.Component {

    constructor(props){
		super(props);
		this.getImage = this.getImage.bind(this);
		this.getTextContent = this.getTextContent.bind(this);
	}

	getImage({index = 0, size = 'url', className = ''}) {
        const { block_images } = this.props;
        return (
            <>  
                {block_images && block_images[index] && 
                    <img 
                        src={block_images[index][size]} 
                        className={className} 
                        alt={block_images[index].asset_alt || ''} 
                    />
                }
            </>
        )
    }
    
	getTextContent() {
        return Parser(this.props.block_textContent || '');
    }

    render() {
        const { block_markdown, data } = this.props;
        return (
            <>
                {block_markdown && 
                    <RenderMarkdown 
                        md={block_markdown} 
                        func={{
                            ...data,
                            getImage: this.getImage,
                            getTextContent: this.getTextContent,
                        }}
                    />
                }
            </>
        );
    }
}

MarkdownContent.propTypes = {
    data: PropTypes.object.isRequired,
    block_markdown: PropTypes.string,
    block_images: PropTypes.array.isRequired,
    block_textContent: PropTypes.string,
}

MarkdownContent.defaultProps = {
    block_markdown: ` `,
    block_textContent: ` `,
    block_images: [],
}

export default MarkdownContent

import { graphql } from 'gatsby'
export const query = graphql`
	fragment NeoPostMarkdownFragment on Craft_post_content_markdownContent_BlockType {
		typeHandle
		block_markdown
        block_textContent
        block_images {
            id
            url
            ... on Craft_assets_Asset {
                large: url(handle: "large")
                medium: url(handle: "medium")
                asset_alt
            }
        }
	}
`