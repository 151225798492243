export const isBrowser = () => typeof window !== "undefined";


export const getUser = () => {
    return isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}
};

export const getAccentColorByLocation = location => {
    const currentLocation = locations.find(l => l.location == location);
    return currentLocation.accentColor || `pink`;
};

export const getUserFromLocation = (location) => {
    return isBrowser() && window.localStorage.getItem(`ardeneUser_${location}`)
    ? JSON.parse(window.localStorage.getItem(`ardeneUser_${location}`))
    : {}
};

const setUser = user => isBrowser() && window.localStorage.setItem("gatsbyUser", JSON.stringify(user));
const setUserByLocation = (user, location) => isBrowser() && window.localStorage.setItem(`ardeneUser_${location}`, JSON.stringify(user));
const allUsers = [
    {
        id: `-bnTh=8^trY&*mbK`,
        username: `store`,
        location: `store`,
        password: `Ardene*2020`,
    },
    {
        id: `79wUwk?+44Hj_C$X`,
        username: `office`,
        location: `office`,
        password: `@rdene1982!`,
    },
];
const locations = [
    {
        location: `store`,
        accentColor: `pink`
    },
    {
        location: `office`,
        accentColor: `turquoise`
    },
];

export const handleLogin = ({ location, password }) => {
    let user = allUsers.find(u => (u.location === location && u.password === password));
    return (user && user.id) ? 
        setUserByLocation({
            id: user.id,
            username: user.username,
        }, user.location)
        : false;
};

export const isLoggedIn = (location = 'store') => {
  const currentUser = getUserFromLocation(location);
  let user = allUsers.find(u => (u.id === currentUser.id && u.location === location));
  return (user && user.id) ? true : false;
}

export const logout = callback => {
    setUser({})
    callback()
}


export const logoutByLocation = (location) => {
    setUserByLocation({}, location);
}
