import React from "react"
import PropTypes from "prop-types"

const Link = ({ block_link__label, block_link__url }) => {
    return (
        <section className="my-8 flex flex-col items-center">
            <div className="flex flex-col lg:flex-row ">
                <a href={block_link__url} target={"_blank"} rel="noreferrer" className={`ardene-btn brand-cta font-bold text-base flex-auto items-center my-4 mx-auto self-center link_underline w-auto text-center accent_underline`}>
                    {block_link__label}
                </a>
            </div>
        </section>
    );
}

Link.propTypes = {
    block_link__label: PropTypes.string.isRequired,
    block_link__url: PropTypes.string.isRequired,
}

export default Link

import { graphql } from 'gatsby'
export const query = graphql`
	fragment NeoPostLinkFragment on Craft_post_content_link_BlockType {
		typeHandle
		block_link__label
        block_link__url
	}
`