import React, { useState } from "react"
import PropTypes from "prop-types"

const Video = ({ block_image, block_image__mobile, block_internalVideo }) =>  {
    const [
        isOpen,
        setIsOpen,
    ] = useState(false)

    const startVideo = (e) => {
      e.preventDefault();
      setIsOpen(!isOpen)
    }

    const getImageURL = (image, fallback) => {
        if(image && image.url){
            return image.url;
        }
        return fallback;
    }

    const videoThumbnailURL__desktop = getImageURL(block_image[0], false);
    const videoThumbnailURL__mobile = getImageURL(block_image__mobile[0], videoThumbnailURL__desktop);


    return (
        <>
            {(block_internalVideo && block_internalVideo[0] && block_internalVideo[0].src) && 
                <section className="video-container small-video">
                    <div className="image w-full">
                        {!isOpen && block_image[0] ? 
                            <div className="image w-full">
                                <a href="!#" onClick={(e) => startVideo(e)} className="cursor-pointer flex flex-col relative w-full">
                                    <img src="/images/playbutton.png" className="playBtn absolute self-center" alt="" />
                                    {videoThumbnailURL__desktop &&
                                        <img src={videoThumbnailURL__desktop} className="desktop w-full" />
                                    }
                                    {videoThumbnailURL__mobile &&
                                        <img src={videoThumbnailURL__mobile} className="mobile w-full" />
                                    }
                               </a>
                            </div>
                        : 
                            <video controls autoPlay={isOpen ? true : false} >
                                <source src={block_internalVideo[0].src} type={block_internalVideo[0].type} />
                            </video>
                        }
                    </div>
                </section>
            }
        </>
    );
    
}

Video.propTypes = {
    block_internalVideo: PropTypes.array,
    block_image: PropTypes.array,
    block_image__mobile: PropTypes.array,
}

Video.defaultProps = {
    block_internalVideo: [],
    block_image: [],
    block_image__mobile: [],
}

export default Video

import { graphql } from 'gatsby'
export const query = graphql`
	fragment NeoPostVideoFragment on Craft_post_content_video_BlockType {
        typeHandle
        block_internalVideo {
            src: url
            title
            type: mimeType
        }
        block_image {
            id
            url
        }
        block_image__mobile {
            id
            url
            ... on Craft_assets_Asset {
				mobile: url(handle: "medium")
            }
        }
    }
`