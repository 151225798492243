import React from "react"
import PropTypes from "prop-types"

import Pdf from "./types/pdf"
import Link from "./types/link"
import Video from "./types/video"
import Gallery from "./types/gallery"
import MarkdownContent from "./types/markdownContent"
import TextContent from "./types/textContent"
import VideoEmbed from "../blocks/types/video"

const components = {
	pdf: Pdf,
	link: Link,
	video: Video,
	videoEmbed: VideoEmbed,
	gallery: Gallery,
	markdownContent: MarkdownContent,
	textContent: TextContent,
}

const Block = ({ block, data }) => {
	const type = block.typeHandle
	const Component = components[type]

	if (Object.keys(components).includes(type)) {
		return <Component {...block} data={data} />
	} else {
		return null
	}
}

Block.propTypes = {
	block: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
}

const PostContent = ({ blocks, ...props }) => (
	<>
		{blocks && blocks.map((block, i) => (
			<Block 
				key={i} 
				block={block} 
				{...props} 
			/>
		))}
	</>
)


PostContent.propTypes = {
	blocks: PropTypes.array,
	data: PropTypes.object.isRequired,
}

export default PostContent

import { graphql } from "gatsby"
export const query = graphql`
	fragment PostContentFragment on Craft_post_content_NeoField {
		...NeoPostTextContentFragment
        ...NeoPostGalleryFragment
        ...NeoPostPDFFragment
        ...NeoPostVideoFragment
        ...NeoPostLinkFragment
        ...NeoPostMarkdownFragment
		...NeoPostVideoEmbedFragment
  	}
`
